import React, { FC, useEffect } from "react";
import { Avatar, Row, Image } from "antd";
import { AppLayout } from "layouts";
import {
  StyledDiv,
  StyledH1,
  StyledParagraph,
  StyledRoundedDiv,
  StyledManagementCol,
  StyledResponsivePragraph,
} from "./About.styled";
import { StyledSmartDiv } from "routes/Dashboard/Dashboard.styled";
import { TitleH3, TitleH5 } from "ui";
import chairman from "../../assets/images/team/sk.jpg";
import dirctorTechnicalImage from "../../assets/images/team/vikas.jpg";
import directorManagement from "../../assets/images/team/vishal.jpg";
import dirctorImage from "../../assets/images/team/shalu.jpg";
import directorTwoImage from "../../assets/images/team/vandana.jpg";

export const About: FC = () => {
  useEffect(() => {
    document.title = `Uttam | About`;
  }, []);

  return (
    <AppLayout>
      <StyledDiv>
        <StyledH1> Our Humble Beginnings </StyledH1>
        <StyledParagraph>
          The group that was established in the year 1964 by our founder, Mr.
          Sudershan Kumar Goel, in a meagre area of 200 square meters, under the
          name of Bharat Iron & Brass Foundry (now merged into Uttam Foundries &
          Ispat Pvt Ltd.), is now spread over an area of over 30,000 square
          meters. The journey began with the supply of manhole covers,
          agricultural implement castings, CI sleeper plates, and castings for
          various government agencies. However, the inclination to manufacture
          better engineering products led to the development of reflux and
          sluice valves.
        </StyledParagraph>
        <StyledParagraph>
          High reliability in the product, achieved through good quality
          control, earned us a reputation as a reliable casting source. In the
          year 1977, the tractor industry began sourcing their castings from us.
          The company, with the sole objective of delivering highly reliable
          products, maintaining competitive price structures, and adhering to
          delivery schedules as well as prompt payment schedules to its
          suppliers, has focused on using high-quality raw materials and process
          control. This focus resulted in a high degree of consistency in the
          product and quality parameters, leading Punjab Tractors, now Mahindra
          & Mahindra Pvt Ltd, Eicher Tractors Ltd, now a Tafe Group and
          International Tractors Ltd to source the majority of their castings
          from us.
        </StyledParagraph>
        <StyledParagraph>
          Recognizing the growing needs of our customers for fully machined
          parts, we established a CNC machine shop in the year 2000, thus
          strengthening our presence in the foundry and machining world.
          Currently, our foundry section is equipped with a fully automated
          molding line with a capacity of approximately 400 tonnes. The machine
          shop is now equipped with around 37 CNC machines, along with a battery
          of SPMs and radial drill machines.
        </StyledParagraph>
        <br />
        <br />
      </StyledDiv>
      <StyledSmartDiv>
        <StyledH1> Our Core Management </StyledH1>
        <Row gutter={[24, 24]} align="middle" justify={"space-between"}>
          <StyledManagementCol xs={24} lg={8}>
            <StyledRoundedDiv>
              <Avatar size={130} icon={<Image width={130} src={chairman} />} />
            </StyledRoundedDiv>
            <TitleH3> Sudershan Kumar Goel </TitleH3>
            <TitleH5> Chairman & Managing Director </TitleH5>
            <StyledResponsivePragraph>
              The founder of our group, equipped with an MA in Economics,
              initiated this foundry in 1964 and possesses over 58 years of
              invaluable experience in this field.
            </StyledResponsivePragraph>
          </StyledManagementCol>
          <StyledManagementCol xs={24} lg={8}>
            <StyledRoundedDiv>
              <Avatar
                size={130}
                icon={<Image width={130} src={dirctorTechnicalImage} />}
              />
            </StyledRoundedDiv>
            <TitleH3> Vikas Goel </TitleH3>
            <TitleH5> Director Technical </TitleH5>
            <StyledResponsivePragraph>
              The elder son of the founder, holding a degree in Mechanical
              Engineering, boasts more than 35 years of experience in this
              profession.
            </StyledResponsivePragraph>
          </StyledManagementCol>
          <StyledManagementCol xs={24} lg={8}>
            <StyledRoundedDiv>
              <Avatar
                size={130}
                icon={<Image width={130} src={directorManagement} />}
              />
            </StyledRoundedDiv>
            <TitleH3> Vishal Goel </TitleH3>
            <TitleH5> Director Commercial </TitleH5>
            <StyledResponsivePragraph>
              The younger son of Mr. Sudershan Kumar Goel, backed by an MBA in
              Marketing, brings over 30 years of expertise to our team.
            </StyledResponsivePragraph>
          </StyledManagementCol>
        </Row>
        <br />
        <Row gutter={[24, 24]} align="middle" justify={"space-between"}>
          <StyledManagementCol xs={24} lg={12}>
            <StyledRoundedDiv>
              <Avatar
                size={130}
                icon={<Image width={130} src={dirctorImage} />}
              />
            </StyledRoundedDiv>
            <TitleH3> Shalu Goel </TitleH3>
            <TitleH5> Director </TitleH5>
          </StyledManagementCol>
          <StyledManagementCol xs={24} lg={12}>
            <StyledRoundedDiv>
              <Avatar
                size={130}
                icon={<Image width={130} src={directorTwoImage} />}
              />
            </StyledRoundedDiv>
            <TitleH3> Vandana Goel </TitleH3>
            <TitleH5> Director </TitleH5>
          </StyledManagementCol>
        </Row>
      </StyledSmartDiv>
      <br />
    </AppLayout>
  );
};
