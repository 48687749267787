export const foundryList: string[] = [
  "450 KW 500 KG dual crucible induction furnace.",
  "Two number automatic mold movement lines.",
  "1 set jolt Squeeze, CT3 molding machines.",
  "One number 500 KG intensive sand mixer with online RTC unit . (Rhino Make)",
  "Complete sand plant with sand cooler.",
  "One number Rhino make high pressure molding machine.",
  "One number Triple hangar dual impeller 500 kg capacity shot blasting machine.",
  "One number wedge breaker.",
  "Well equipped fettling shop.",
  "A power backup with 625 KW gen-set, 125 KW gen-set.",
  "100 KW solar panel unit.",
  "50 HP compressor, 40 HP compressor , air dryer unit.",
  "Pattern shop.",
  "Core shop with core making facility.",
];

export const machineShopData = [
  {
    title: "CNC turning centers (16 No’s)",
    list: ["7 No’s with A28 spindle", "5 No’s with A26 spindle"],
  },
  {
    title: "VMC (17 No’s)",
    list: [
      "2 No’s (700 x 400)",
      "2 No’s (700 x 570)",
      "6 No’s (600 x 450)",
      "1 No. (700 x 570) With Rotary",
    ],
  },
  {
    title: "HMC (4 No's)",
    list: ["3 No’s ( 500 x 500)", "1 No. ( 410 x 410)"],
  },
  {
    title: "CMM (1 No.)",
    list: ["1 No. ( 500 x 600 x 800)2"],
  },
  {
    title: "VBM (1 No.)",
    list: ["1 No. ( 500 x 600 x 800)2"],  //New Added
  },
];

export const testingImages: string[] = [
  "testing_1.jpg",
  "testing_2.jpg",
  "testing_3.jpg",
  "testing_4.jpg",
  "testing_5.jpg",
  "testing_6.jpg",
];

export const SAND_TESTING_EQUIPMENT = [
  "Sieve Shaker",
  "AFS Moisture Tester",
  "Sand Rammer",
  "Mould & Core Hardness Tester",
  "Permeability Tester",
  "Hydrometer",
  "GCS Testing Machine",
];

export const METALLOGRAPHIC_TESTING_EQUIPMENT = [
  "Carbon Silicon Analyzer",
  "Wet Analysis Lab",
  "Strohleins Apparatus",
  "Portable Metallurgical Microscope with Camera and Software",
  "Inverted Metallurgical Microscope",
  "22 element spectrometer",
];

export const PHYSICAL_TESTING_EQUIPMENT = [
  "Brinell Hardness Tester",
  "Rockwell Hardness Tester",
  "Immersion Pyrometer",
  "Universal Testing Machine (UTM)",
  "Weighing Balance (Platform Type)",
  "Weighing Balance (Pan Type)",
  "Ford Cup",
  "DFT Gauge",
];

export const DIMENSIONAL_TESTING_EQUIPMENT = [
  "Cordinate Measuring Machine (CMM)",
  "Roughness Tester",
  "Air Gauge Unit",
  "Pistol Calliper",
  "Micrometer (Range 0-400mm)",
  "Height Gauge (Range 0-600mm)",
  "Slip Gauge Set (Range 1-100mm)",
];
